
//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import BarChart from "@/comp/Charts/BarChart.vue";
import LineChart from "@/comp/Charts/LineChart.vue";
import Pagination from "@/comp/Pagination/index.vue";
import { getUserOnlinenum } from "@/api/request/bigdata";

//组件
@Component({
  name: "OnlineUsers",
  components: {
    BarChart,
    LineChart,
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private dateList: any[] = [];
  private userList: any[] = [];
  private checked: boolean = false;
  private listLoading: boolean = true;
  private currentIndex: string = "0";

  //请求参数
  private listQuery: any = {
    type: 0,
    internal: 0,
    date_time: "",
  };

  //用户类型
  private typeOptions: any[] = [
    { value: 0, label: "全部" },
    { value: 1, label: "全部用户" },
    { value: 2, label: "博主" },
    { value: 3, label: "男用户" },
    { value: 4, label: "女用户" },
  ];

  //创建
  created() {
    //初始化时间
    this.initTime();

    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //判断显示
    if (this.listQuery.date_time == "" || this.listQuery.date_time == undefined) return Message({ message: "请选择日期！", type: "error", duration: 5 * 1000 });

    //数据赋值
    this.userList = [];
    this.dateList = [];

    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getUserOnlinenum(this.listQuery);

    //数据赋值
    this.list = data;
    if (this.list.length) {
      this.list.forEach((item: any) => {
        this.dateList.push(`${item.date_time_hour}点`);
        this.userList.push(item.online_num);
      });
    }

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //初始化时间
  private initTime(): void {
    //数据赋值
    this.listQuery.date_time = `${DTCls.getCurDateTimeYMD()}`;
  }

  //处理重置
  private handleReset(): void {
    //初始化时间
    this.initTime();

    //数据赋值
    this.listQuery.type = 0;

    //获取数据
    this.getList();
  }

  //处理查询
  private handleFilter(): void {
    //获取数据
    this.getList();
  }

  //Tab切换
  private changeTab(val: any): void {
    //数据赋值
    this.currentIndex = val.index;
  }

  //内部选项切换
  private checkChange(val: any): void {
    //数据赋值
    this.listQuery.internal = val ? 1 : 0;

    //获取数据
    this.getList();
  }
}
